import store from '@/store'

export default {

  computed:{
    routerMap(){
      let map = {};
      for (let router of this.$router.options.routes){
        map[router.name] = router;
      }
      return map;
    }
  },
  methods: {
    getMetaFromRouter(routerName, metaKey){
      let router = this.routerMap[routerName];
      if (router && router.meta){
        return router.meta[metaKey];
      }
      return null;
    },
    routerIsAccessible(routerName){
      if (!routerName){return true;}
      let store = this.$store;
      let permissions = this.getMetaFromRouter(routerName, "permissions");
      if (Array.isArray(permissions)){
        return !permissions.some(p => !store.getters['user/hasPermission'](p));
      }else {
        return true;
      }
    },
    firstRouteAccessible(){
        let router = this.$router.options.routes.find(r => r.name !== 'home' && this.routerIsAccessible(r.name));
        if (router){
            return router.name;
        }
        return null;
    }
  }
}
