<template>
  <div>
<!--    <calendar-bar v-model="dateRange" @input="refresh"/>-->
  </div>
</template>

<script>
import CalendarBar from '@/components/widget/CalendarBar'
import mixinRouter from '@/components/mixin/mixinRouter'

export default {
  name: 'MainDashboard',
  mixins: [mixinRouter],
  components: { CalendarBar },
  data: () => ({
    dateRange: {},
    loading: {
      stats: true,
    },
    stats: {},
  }),
  created() {
    let firstRouteAccessible = this.firstRouteAccessible();
    // console.log('firstRouteAccessible', firstRouteAccessible)
    this.$router.push({ name: firstRouteAccessible })
    this.refresh()
  },
  methods: {
    refresh() {

    }
  }
}
</script>

<style scoped>

</style>
